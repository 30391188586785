import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {Html, OrbitControls, PerspectiveCamera} from "@react-three/drei";
import * as THREE from "three";

export function ClusterList() {
  const [clusters, setClusters] = useState([]);

  useEffect(() => {
    axios
      .get("https://backendserver.org/cluster_names")
      .then((response) => {
        setClusters(response.data);
      })
      .catch((error) => {
        console.error("Error fetching clusters:", error);
      });
  }, []);

  return (
    <div className="cluster-list">
      {clusters.map((clusterName, index) => {
        const displayName = clusterName.replace(/_/g, " ");
        return (
          <Link
            key={index}
            to={`/cluster/${clusterName}`}
            className="cluster-item"
          >
            <div className="cluster-name">{displayName}</div>
            <img
              src={`https://backendserver.org/cluster_logo/${clusterName}`}
              alt={`Cluster ${displayName}`}
              className="cluster-image"
            />
          </Link>
        );
      })}
    </div>
  );
}

export function ClusterFullImage() {
  const { name, index } = useParams();
  const navigate = useNavigate();
  const currentIndex = parseInt(index, 10);
  const [thumbnails, setThumbnails] = useState([]);
  const [similarThumbnails, setSimilarThumbnails] = useState([]);

  useEffect(() => {
    axios
      .get(`https://backendserver.org/cluster_thumbnails/${name}`)
      .then((response) => {
        setThumbnails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching cluster thumbnails:", error);
      });
  }, [name]);

  const nextImage = useCallback(() => {
    if (currentIndex < thumbnails.length - 1) {
      navigate(`/cluster/${name}/image/${currentIndex + 1}`);
      setSimilarThumbnails([]);
    }
  }, [currentIndex, thumbnails.length, navigate, name]);

  const prevImage = useCallback(() => {
    if (currentIndex > 0) {
      navigate(`/cluster/${name}/image/${currentIndex - 1}`);
      setSimilarThumbnails([]);
    }
  }, [currentIndex, navigate, name]);

  const findSimilarImages = async () => {
    const imageName = thumbnails[currentIndex].split("/").pop();
    let match = imageName.match(/-(\d+)\./);
    const response = await axios.get(
      `https://backendserver.org/get_similar_image/${match[1]}`
    );
    setSimilarThumbnails(response.data);
  };

  const handleThumbnailClick = (similarIndex) => {
    navigate(`/similar-image/${similarIndex}`);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        nextImage();
      } else if (event.key === "ArrowLeft") {
        prevImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextImage, prevImage]);

  if (thumbnails.length === 0) {
    return <div>Loading...</div>;
  }

  const thumbnail = thumbnails[currentIndex];
  const imageName = thumbnail.split("/").pop();
  const fullImageUrl = `https://backendserver.org/cluster_image/${name}/${imageName}`;

  return (
    <div className="full-image-container">
      <div className="arrow left" onClick={prevImage}>
        &#10094;
      </div>
      <img
        src={fullImageUrl}
        alt="Full size"
        className={`full-image ${
          similarThumbnails.length > 0 ? "scaled-down" : ""
        }`}
      />
      <div className="arrow right" onClick={nextImage}>
        &#10095;
      </div>
      <div className="button-container">
        <button onClick={findSimilarImages} className="find-similar-button">
          Find Similar Images
        </button>
      </div>
      {similarThumbnails.length > 0 && (
        <div className="similar-thumbnails">
          {similarThumbnails.map((similarThumbnail, idx) => {
            const similarIndex = similarThumbnail.split("/").pop();
            return (
              <img
                key={idx}
                src={`https://backendserver.org${similarThumbnail}`}
                alt="Similar thumbnail"
                className="thumbnail"
                onClick={() => handleThumbnailClick(similarIndex)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export function ClusterThumbnails() {
  const { name } = useParams();
  const [thumbnails, setThumbnails] = useState([]);

  useEffect(() => {
    axios
      .get(`https://backendserver.org/cluster_thumbnails/${name}`)
      .then((response) => {
        setThumbnails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching cluster thumbnails:", error);
      });
  }, [name]);

  return (
    <div className="thumbnail-grid masonry">
      {thumbnails.map((thumbnail, index) => (
        <div key={index} className="masonry-item">
          <Link to={`/cluster/${name}/image/${index}`}>
            <img
              src={`https://backendserver.org${thumbnail}`}
              alt={`Thumbnail ${index}`}
            />
          </Link>
        </div>
      ))}
    </div>
  );
}

function Scene({ data, clusterColors, handleDotClick, selectedColor }) {
  const { camera } = useThree();
  const [visibleImages, setVisibleImages] = useState(new Array(data.coordinates.length).fill(false));

  useFrame(() => {
    if (selectedColor === null) {
      // Calculate distances and update visibility
      const distances = data.coordinates.map(coord => {
        const point = new THREE.Vector3(...coord);
        const distance = camera.position.distanceTo(point);
        return distance;
      });

      // Set visibility based on distance threshold
      setVisibleImages(distances.map(distance => distance < 20)); // Adjust the threshold value as needed
    } else {
      // Make all images of the selected color visible
      const updatedVisibility = data.clusters.map((cluster, index) => {
        return clusterColors[cluster % clusterColors.length] === selectedColor;
      });
      setVisibleImages(updatedVisibility);
    }
  });

  const handleDotClickInternal = (color) => {
    handleDotClick(color);  // Pass selected color back to parent component
  };

  return (
    <>
      <PerspectiveCamera makeDefault position={[40, 40, 30]} fov={75} />
      <OrbitControls />
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      {data.coordinates.map((coord, index) => {
        const clusterColor = clusterColors[data.clusters[index] % clusterColors.length];
        return (
          <mesh
            key={index}
            position={coord}
            onClick={() => handleDotClickInternal(clusterColor)}
          >
            <sphereGeometry args={[0.5, 16, 16]} />
            <meshStandardMaterial color={clusterColor} />
            {visibleImages[index] && (
              <Html position={[0, 0.6, 0]} className="image-overlay">
                <img
                  src={`https://backendserver.org/get_thumbnail/${String(index + 1).padStart(3, '0')}.jpg`}
                  alt={`Dot ${index}`}
                  style={{
                    width: '5vw',
                    minWidth: '30px',
                    height: 'auto',
                    border: `2px solid ${clusterColor}`,
                    borderRadius: '4px',
                    background: '#fff',
                  }}
                />
              </Html>
            )}
          </mesh>
        );
      })}
    </>
  );
}

export function Cluster_overview() {
  const [data, setData] = useState({ coordinates: [], clusters: [] });
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedModel, setSelectedModel] = useState("Wasserstein"); // Default selection

  useEffect(() => {
    const url =
      selectedModel === "VAE"
        ? "https://backendserver.org/cluster_overview/image_data_vae"
        : "https://backendserver.org/cluster_overview/image_data";

    fetch(url)
      .then((response) => response.json())
      .then((fetchedData) => {
        setData(JSON.parse(fetchedData));
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [selectedModel]); // Fetch data when selectedModel changes

  const clusterColors = ["#ff0000", "#00ff00", "#0000ff", "#ffff00", "#ff00ff"];

  const handleDotClick = (color) => {
    if (selectedColor === color) {
      setSelectedColor(null);
    } else {
      setSelectedColor(color);
    }
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value); // Update the selected model when dropdown changes
  };

  const selectedImages = data.coordinates.map((coord, index) => {
    if (selectedColor && clusterColors[data.clusters[index] % clusterColors.length] === selectedColor) {
      return (
        <img
          key={index}
          src={`https://backendserver.org/get_thumbnail/${String(index + 1).padStart(3, '0')}.jpg`}
          alt={`Cluster ${index}`}
          style={{
            width: '100%',
            minWidth: '30px',
            height: 'auto',
            marginBottom: '10px',
            border: `2px solid ${selectedColor}`,
            borderRadius: '4px',
            background: '#fff',
          }}
        />
      );
    }
    return null;
  });

  return (
    <div style={{ display: 'flex' }}>
      {/* Dropdown to select model */}
      <div style={{ position: 'absolute', top: 100, left: 10, zIndex: 1000 }}>
        <label htmlFor="model-select" style={{ color: '#fff', marginRight: '10px' }}>Select Model:</label>
        <select id="model-select" value={selectedModel} onChange={handleModelChange}>
          <option value="Wasserstein">Wasserstein</option>
          <option value="VAE">VAE</option>
        </select>
      </div>

      {/* Left side: 3D visualization */}
      <div style={{ width: '80vw', height: '93vh' }}>
        <Canvas camera={{ position: [0, 10, 10], fov: 75 }}>
          <Scene data={data} clusterColors={clusterColors} handleDotClick={handleDotClick} selectedColor={selectedColor} />
        </Canvas>
      </div>

      {/* Right side: Sidebar to display images */}
      <div style={{
        width: '20vw',
        height: '93vh',
        overflowY: 'scroll',
        padding: '10px',
        backgroundColor: '#121212',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none'
      }}>
        <style>
          {`
            /* For Webkit browsers */
            div::-webkit-scrollbar {
              width: 0;
            }
          `}
        </style>

        {selectedColor ? (
          selectedImages.filter(Boolean) // Only render non-null images
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
}