import Latex from 'react-latex';
import 'katex/dist/katex.min.css'; // Include KaTeX CSS for LaTeX rendering

function BlogPage() {
  const idea = `
    The image retrieval is based on the paper "The earth mover's distance as a metric for image retrieval".
    The idea is to represent images as probability metrics and then use the Wasserstein distance to find the closest images in a small database of images.
  `;
  const images = `
    RGB-images are encoded as $I \\in [0, 256]^{n \\times m \\times 3}$. For the task of image comparison the LAB representation is more useful, as the Euclidean distance in the LAB space corresponds better to the way humans see colors. In LAB space pixels are represented as $x \\in [0, 100] \\times [-128, 127]^2$.
    In order to do quick computations with images, we can use compressed representations. For this data-binning is used. When encoding an image in LAB space, we end up with $N = n \\times m$ color values $c_1,...,c_N \\in [0,100]\\times[-128, 127]^2$. To compress the image we calculate $L$ 'three-dimensional' bins with centers $\\nu_1,...,\\nu_L \\in [0,100] \\times [-128, 127]^2$ and weights $w_i \\in [0,1]$ as the relative frequency of pixels in this bin. We then end up with a probability measure $\\mu_I = \\sum^k_{i=1} w_i \\delta_{\\nu_i} \\in P([0, 100] \\times [-128, 127]^2)$ for each image.
  `;
  const wasserstein = `
    In order to compare probability measures, we need probability distances. A very popular distance is the Wasserstein distance, which works especially well with structures like the euclidean structures in images. Wasserstein distances are part of the optimal transport distances. The optimal transport distance basically tells one how to move one distribution to another distribution, with minimal cost, defined by the cost function $c$. Let $\\mu \\in P(X_1), \\nu \\in P(X_2)$ and $c \\in L^1(\\mu \\otimes \\nu)$, then the mathematical definition of optimal transport is given by $OT_c(\\mu, \\nu) = inf_{\\pi\\in\\Pi(\\mu, \\nu)} \\int c \\ d\\pi$. Here $\\Pi(\\mu, \\nu)$ is the set of couplings between $\\mu$ and $\\nu$, meaning $\\Pi(\\mu, \\nu) = \\{\\pi \\in P(X_1 \\times X_2): \\pi_1 = \\mu, \\pi_2 = \\nu\\}$, where $\\pi_1$ and $\\pi_2$ are the marginals of $\\pi$.   
    Let $d_X$ be a lower semicontinuous metric on $X$ and $p \\geq 1$. Then the Wasserstein $p$-distance $W_p$ is defined as $W_p(\\mu, \\nu) = (min_{\\pi \\in \\Pi (\\mu, \\nu)} \\int d_X (x, y)^p \\pi (dx, dy))^{1/p}$.
  `;
  const all = `
    In order to be able to look up similar images to the shown images on the website the two presented concepts are combined. For all shown images and all images in the database, the probability measures representing the images were computed and saved. When searching for similar images to a given images the $W_1$ distance with the euclidean norm as the metric is computed. We use discrete probability measures as representations of the images and for discrete probability measure the optimal transport problem corresponds to a linear program. For these programs there are specialized solvers available in the Python Optimal Transport (POT) libraray, which was used for the computations. As the images are compressed this computation is not computationally heavy and can be done live on the server. After calculating the distance to all database images with the smallest distance are displayed.
  `;
  return (
    <div className="blog-container">
      <h1>Image retrieval</h1>
        <div className="section-container">
            <div className="latex-content">
                <h2>1. Idea</h2>
                <Latex>{idea}</Latex>
            </div>
            <div className="empty-container"></div>
        </div>

        <div className="section-container">
        <div className="latex-content">
          <h2>2. Basic concepts</h2>
          <h3>2.1 Images as probability measures</h3>
          <Latex>{images}</Latex>
        </div>
        <video className="video-content" controls>
          <source src="/videos/ImagesAsMeasures.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="section-container">
        <div className="latex-content">
          <h3>2.2 Wasserstein distance</h3>
          <Latex>{wasserstein}</Latex>
        </div>
        <video className="video-content" controls>
          <source src="/videos/Wasserstein.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="section-container">
        <div className="latex-content">
          <h2>3. Putting it all together</h2>
          <Latex>{all}</Latex>
        </div>
        <video className="video-content" controls>
          <source src="/videos/AllTogether.mp4" type="video/mp4" />
        </video>
      </div>

      <div className="spacer"></div>
    </div>
  );
}

export default BlogPage;