import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link, useParams, useNavigate } from 'react-router-dom';
import './App.css';
import {ClusterFullImage, ClusterThumbnails, Cluster_overview} from './Cluster'
import {Map} from './map'
import BlogPage from './BlogPage';

function App() {
  const [thumbnails, setThumbnails] = useState([]);
  const [darkMode] = useState(true);

  useEffect(() => {
    axios.get('https://backendserver.org/get_thumbnails')
      .then(response => {
        setThumbnails(response.data);
      })
      .catch(error => {
        console.error('Error fetching thumbnails:', error);
      });
  }, []);

  return (
    <Router>
      <div className={`App ${darkMode ? 'dark' : ''}`}>
          <header>
              <div className="header-left">
                  <Link to="/" className="gallery-title">Image Gallery</Link>
              </div>
              <div className="header-center">
                  <Link to="/clusters" className="cluster-button">Clusters</Link>
              </div>
              <div className="header-right">
                  <Link to="/map" className="map-button">Map</Link>
              </div>
          </header>
          <Routes>
              <Route path="/" element={<ThumbnailGrid thumbnails={thumbnails}/>}/>
              <Route path="/image/:index" element={<FullImage thumbnails={thumbnails} />} />
          <Route path="/map" element={<Map />} />
          <Route path="/clusters" element={<Cluster_overview />} />
          <Route path="/cluster/:name" element={<ClusterThumbnails />} />
          <Route path="/cluster/:name/image/:index" element={<ClusterFullImage />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutzerklaerung" element={<Datenschutzerklaerung />} />
            <Route path="/similar-image/:index" element={<SimilarImageFullView />} /><Route path="/blog-page" element={<BlogPage />} /> {/* New route for the blog */}
          <Route path="/about" element={<About />} /> {/* New route for About page */}
            <Route path="/blog-page" element={<BlogPage />} />
          </Routes>
      </div>
    </Router>
  );
}

function About() {
  return (
    <div className="about-page">
      <h1>About This Site</h1>
      <p>Hi, this is my website with my images, I captured over the years. In the near future, this site will be supported
       by some machine learning algorithms I am developing to sort, search and generate images.</p>
    </div>
  );
}

function Impressum() {
  return (
    <div>
      <h1>Impressum</h1>
      <p>Fabian Morelli<br />Im Baeumle 9<br />75242 Neuhausen</p>
      <h2>Kontakt</h2>
      <p>E-Mail: fabian.morelli@web.de</p>
    </div>
  );
}

function Datenschutzerklaerung() {
  return (
    <div>
      <h1>Datenschutz&shy;erkl&auml;rung</h1>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h3>Allgemeine Hinweise</h3> <p>Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</p>
      <h3>Datenerfassung auf dieser Website</h3> <h4>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</h4> <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.</p> <h4>Wie erfassen wir Ihre Daten?</h4> <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p> <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p> <h4>Wof&uuml;r nutzen wir Ihre Daten?</h4> <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.</p> <h4>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</h4> <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</p> <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</p>
      <h2>2. Hosting</h2>
      <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p>
      <h3>Strato</h3> <p>Anbieter ist die Strato AG, Otto-Ostrowski-Stra&szlig;e 7, 10249 Berlin (nachfolgend &bdquo;Strato&ldquo;). Wenn Sie unsere Website besuchen, erfasst Strato verschiedene Logfiles inklusive Ihrer IP-Adressen.</p> <p>Weitere Informationen entnehmen Sie der Datenschutzerkl&auml;rung von Strato: <a href="https://www.strato.de/datenschutz/" target="_blank" rel="noopener noreferrer">https://www.strato.de/datenschutz/</a>.</p> <p>Die Verwendung von Strato erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TDDDG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TDDDG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
    </div>
  );
}

function Footer() {
  return (
    <div className="footer">
      <Link to="/impressum">Impressum</Link>
      <Link to="/datenschutzerklaerung">Datenschutzerklärung</Link>
    </div>
  );
}


function ThumbnailGrid({ thumbnails }) {
  return (
    <div className="thumbnail-grid masonry">
      {thumbnails.map((thumbnail, index) => (
        <div key={index} className="masonry-item">
          <Link to={`/image/${index}`}>
            <img
              src={`https://backendserver.org${thumbnail}`}
              alt={`Thumbnail ${index}`}
            />
          </Link>
        </div>
      ))}
      <Footer />
    </div>
  );
}

function FullImage({ thumbnails }) {
  const { index } = useParams();
  const navigate = useNavigate();
  const currentIndex = parseInt(index, 10);
  const thumbnail = thumbnails[currentIndex];
  const fullImageUrl = `https://backendserver.org${thumbnail.replace('get_thumbnail', 'get_image')}`;

  const [similarThumbnails, setSimilarThumbnails] = useState([]);

  const nextImage = useCallback(() => {
    if (currentIndex < thumbnails.length - 1) {
      navigate(`/image/${currentIndex + 1}`);
      setSimilarThumbnails([]); // Clear similar thumbnails on navigation
    }
  }, [currentIndex, thumbnails.length, navigate]);

  const prevImage = useCallback(() => {
    if (currentIndex > 0) {
      navigate(`/image/${currentIndex - 1}`);
      setSimilarThumbnails([]); // Clear similar thumbnails on navigation
    }
  }, [currentIndex, navigate]);

  const findSimilarImages = async () => {
    const imageName = thumbnail.split('/').pop(); // Extract image name from the path
    const response = await fetch(`https://backendserver.org/get_similar_image/${imageName}`);
    const data = await response.json();
    setSimilarThumbnails(data); // Assuming data is the array of similar thumbnail paths
  };

  const handleThumbnailClick = (similarIndex) => {
    navigate(`/similar-image/${similarIndex}`);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
        nextImage();
      } else if (event.key === 'ArrowLeft') {
        prevImage();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [nextImage, prevImage]);

  return (
    <div className="full-image-container">
      <div className="arrow left" onClick={prevImage}>&#10094;</div>
      <img
        src={fullImageUrl}
        alt="Full size"
        className={`full-image ${similarThumbnails.length > 0 ? 'scaled-down' : ''}`}
      />
      <div className="arrow right" onClick={nextImage}>&#10095;</div>
      <div className="button-container">
        <button onClick={findSimilarImages} className="find-similar-button">Find Similar Images</button>
        <Link to="/blog-page" className="help-button">
            <img src="/question_mark.png" alt="Help" className="question-mark-icon" />
        </Link>
      </div>
      {similarThumbnails.length > 0 && (
        <div className="similar-thumbnails">
          {similarThumbnails.map((similarThumbnail, idx) => {
            const similarIndex = similarThumbnail.split('/').pop(); // Extract index from the path
            return (
              <img
                key={idx}
                src={`https://backendserver.org${similarThumbnail}`}
                alt="Similar thumbnail"
                className="thumbnail"
                onClick={() => handleThumbnailClick(similarIndex)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

function SimilarImageFullView() {
  const { index } = useParams();
  const fullImageUrl = `https://backendserver.org/get_image_rest/${index}`;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        window.history.back();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="full-image-container">
      <img src={fullImageUrl} alt="Full size" className="full-image" />
    </div>
  );
}

export default App;
